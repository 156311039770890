import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "dkn" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dkn-träningsutrustning"
    }}>{`DKN Träningsutrustning`}</h1>
    <p>{`Välkommen till vår brand-sida för DKN, ett ledande varumärke inom träningsutrustning. Här hittar du detaljerade beskrivningar av DKN:s olika produktserier, utformade för att tillfredsställa alla dina träningsbehov. Oavsett om du är nybörjare eller en erfaren atlet, kommer DKN:s sortiment garanterat att erbjuda något som passar dig.`}</p>
    <h2 {...{
      "id": "dkn-airun-c-v2"
    }}>{`DKN AiRun-C V2`}</h2>
    <p><strong parentName="p">{`DKN AiRun-C V2`}</strong>{` är ett gåband speciellt designat för hemmaträning, som kombinerar effektivitet och platsbesparing med högteknologiska funktioner. Den kompakta, hopfällbara designen gör det enkelt att förvara när det inte används. Med en topphastighet på 15 km/h och 15 olika träningsprogram, erbjuder detta gåband en varierande och anpassad träning upplevelse. Bluetooth-förmåga möjliggör enkel synkronisering med dina favorit träningsappar, vilket gör din träning interaktiv och engagerande. Med DKN AiRun-C V2 tar du smart träning till nästa nivå direkt i ditt hem.`}</p>
    <h3 {...{
      "id": "huvudfunktioner"
    }}>{`Huvudfunktioner:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hopfällbar design`}</strong>{`: Lätt att förvara`}</li>
      <li parentName="ul"><strong parentName="li">{`15 träningsprogram`}</strong>{`: Anpassa din träning`}</li>
      <li parentName="ul"><strong parentName="li">{`Bluetooth-funktionalitet`}</strong>{`: Synka med träningsappar`}</li>
      <li parentName="ul"><strong parentName="li">{`Toppfart 15 km/h`}</strong>{`: För både nybörjare och erfarna`}</li>
    </ul>
    <p>{`DKN AiRun-C V2 är det perfekta valet för dig som söker en effektiv och platsbesparande lösning för hemmaträning.`}</p>
    <h2 {...{
      "id": "dkn-airun-z"
    }}>{`DKN AiRun-Z`}</h2>
    <p><strong parentName="p">{`DKN AiRun-Z`}</strong>{` är ett toppmodernt löpband som förenar stil, komfort och hög prestanda. Denna modell är utrustad med en kraftfull 2.5 HK motor och har en hastighetskapacitet på upp till 18 km/h. Lägg till detta en extra bred löpyta på 52 cm och den innovativa Progressive Shock Absorption-tekniken för optimal komfort under din träningsrunda. AiRun-Z är dessutom hopfällbar och enkel att förvara. Bluetooth-stöd ger dig möjlighet att ansluta till olika träningsappar och anpassa din träning. Med 12 förkonfigurerade program samt justerbara användarprofiler och HRC-program får du en skräddarsydd och effektiv träning i ditt eget hem.`}</p>
    <h3 {...{
      "id": "huvudfunktioner-1"
    }}>{`Huvudfunktioner:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`2.5 HK motor`}</strong>{`: Höga hastigheter och prestanda`}</li>
      <li parentName="ul"><strong parentName="li">{`18 km/h maxhastighet`}</strong>{`: För intensiv träning`}</li>
      <li parentName="ul"><strong parentName="li">{`52 cm löpyta`}</strong>{`: Mer plats för komfort`}</li>
      <li parentName="ul"><strong parentName="li">{`Progressive Shock Absorption`}</strong>{`: Skonsamt för lederna`}</li>
      <li parentName="ul"><strong parentName="li">{`Bluetooth`}</strong>{`: Interaktiv träning`}</li>
    </ul>
    <p>{`DKN AiRun-Z är idealisk för dig som vill ha ett högpresterande löpband för intensiv hemmaträning.`}</p>
    <h2 {...{
      "id": "köpguide-för-dkn-träningsutrustning"
    }}>{`Köpguide för DKN Träningsutrustning`}</h2>
    <p>{`Att välja rätt träningsutrustning kan vara en utmaning, men vi är här för att hjälpa dig att fatta bästa möjliga beslut. Här är några tips för att guida dig:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Användningsområde`}</strong>{`: Om du främst planerar att gå, välj AiRun-C V2 för dess kompakta och effektiva design. För intensiv löpning är AiRun-Z det bästa alternativet med sin starka motor och breda löpyta.`}</li>
      <li parentName="ol"><strong parentName="li">{`Plats`}</strong>{`: Om du har begränsat med utrymme, är AiRun-C V2 idealisk tack vare dess hopfällbara design.`}</li>
      <li parentName="ol"><strong parentName="li">{`Interaktiv träning`}</strong>{`: Både AiRun-C V2 och AiRun-Z har Bluetooth-funktionalitet för att enkelt koppla upp till träningsappar och göra träningen mer engaging.`}</li>
      <li parentName="ol"><strong parentName="li">{`Träningsnivå`}</strong>{`: AiRun-C V2 passar alla nivåer men är optimal för börjares och medelnivåer, medan AiRun-Z är designad för mer erfarna användare som kräver mer från sin utrustning.`}</li>
    </ol>
    <h3 {...{
      "id": "sammanfattning"
    }}>{`Sammanfattning`}</h3>
    <p>{`DKN erbjuder ett varierande utbud av högkvalitativ träningsutrustning som är idealisk för alla träningsnivåer. Genom att förstå dina behov och preferenser kan du välja den produkt som bäst passar din livsstil och träningsmål. Utforska vårt sortiment av DKN träningsutrustning och börja din resa mot en hälsosammare livsstil idag!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      